<template>
  <div style="margin-top: 30px;">
    <el-steps :active="step" class="p-4">
      <el-step
        :title="__('Step 1')"
        :description="__('Select Service Provider')"
        icon="el-icon-edit"
      ></el-step>
      <template v-if="isBatchMigration">
        <el-step
          :title="__('Step 2')"
          :description="__('Select Accounts')"
          icon="el-icon-user"
        ></el-step>
        <el-step
          :title="__('Step 3')"
          :description="__('Select Tasks')"
          icon="el-icon-thumb"
        ></el-step>
        <el-step
          :title="__('Step 4')"
          :description="__('Batch Migration')"
          icon="el-icon-coffee-cup"
        ></el-step>
      </template>
      <template v-else>
        <el-step
          :title="__('Step 2')"
          :description="__('Select Account')"
          icon="el-icon-upload"
        ></el-step>
        <el-step
          :title="__('Step 3')"
          :description="__('Start Content Migration')"
          icon="el-icon-picture"
        ></el-step>
        <el-step
          :title="__('Step 4')"
          :description="__('Start Nodes Migration')"
          icon="el-icon-picture"
        ></el-step>
        <el-step
          :title="__('Step 5')"
          :description="__('Start Canvases Migration')"
          icon="el-icon-picture"
        ></el-step>
        <el-step
          :title="__('Step 6')"
          :description="__('Result')"
          icon="el-icon-picture"
        ></el-step>
      </template>
    </el-steps>
    <el-form
      ref="contentForm"
      :rules="rules"
      :model="contentForm"
      label-position="top"
      size="large"
      v-loading="isLoading"
      class="form-container"
      style="padding-left: 0.7rem"
    >
      <div
        v-show="step === 0"
        v-loading="studio6spLoading || s7SpDataLoading || s6SpDataLoading"
      >
        <el-row type="flex">
          <el-col :span="12" :offset="0">
            <el-form-item
              :label="__('Studio 6 Service Provider')"
              prop="s6_sp_id"
            >
              <el-select
                value-key="s6_sp_id"
                v-model="contentForm.s6_sp_id"
                :placeholder="__('Select')"
                style="width: 100%"
                @change="handleToSpSelection"
                default-first-option
                filterable
                remote
                :remote-method="getS6SpDataWithSearch"
              >
                <div class="infinite-list" v-infinite-scroll="getS6SpData">
                  <el-option
                    v-for="serviceProvider in this.s6SpData"
                    :key="serviceProvider.service_provider_id"
                    :label="serviceProvider.service_provider_name"
                    :value="serviceProvider.service_provider_id"
                  >
                  </el-option>
                </div>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" :offset="0"></el-col>
          <el-col :span="6" :offset="0">
            <el-button @click="viewUnmappedItems">
              {{ __("View Previously Mapped SPs and ACs") }}
            </el-button>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12" :offset="0">
            <el-form-item :label="__('Studio 7 Service Provider')" prop="sp_id">
              <el-select
                value-key="sp_id"
                v-model="mapped_s7_sp_id"
                placeholder="Select"
                style="width: 100%"
                @change="handleSpSelection"
                default-first-option
                filterable
                :disabled="this.disableSp"
                remote
                :remote-method="getS7SpDataWithSearch"
              >
                <div class="infinite-list" v-infinite-scroll="getS7SpData">
                  <el-option
                    v-for="serviceProvider in s7SpData"
                    :key="serviceProvider.sp_id"
                    :label="serviceProvider.sp_name"
                    :value="serviceProvider.sp_id"
                  >
                  </el-option>
                </div>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" v-show="this.spAlreadyMapped">
          {{ __("The selected service provider is already mapped.") }}
        </el-row>
        <el-row v-show="!this.spPlanExists">
          <!-- eslint-disable-next-line -->
          {{ __("The selected service provider does not have a plan assigned to it.") }}
        </el-row>
        <el-dialog
          :title="__('Previously Mapped Service Providers and Accounts')"
          :visible.sync="unmappedItemsDialogVisible"
          :show-close="true"
          destroy-on-close
          width="50%"
          v-if="unmappedItemsDialogVisible"
        >
          <unmapped-sp-ac></unmapped-sp-ac>
        </el-dialog>
      </div>
      <div
        v-show="step === 1"
        v-loading="s6GroupDataLoading || s7AcDataLoading || s6GroupDataLoading"
      >
        <template v-if="isBatchMigration">
          <el-row
            type="flex"
            v-show="showBatchMigrationAcWarningMessage"
            class="mb-15"
          >
            <el-alert
              title="Warning"
              type="warning"
              :description="batchMigrationAcWarningMessage"
              show-icon
            />
          </el-row>

          <el-row type="flex">
            <el-col :span="12" :offset="0">
              <el-form-item :label="__('Studio 6 Accounts')" prop="s6_ac_id">
                <el-select
                  value-key="s6_group_id"
                  v-model="contentForm.batch_s6_ac_ids"
                  :placeholder="__('Select')"
                  style="width: 100%"
                  @change="handleBatchAccountSelection"
                  multiple
                  filterable
                  remote
                  :remote-method="getBatchS6S7AccountDataWithSearch"
                  default-first-option
                >
                  <div
                    class="infinite-list"
                    v-infinite-scroll="getBatchS6S7AccountData"
                  >
                    <el-option
                      v-for="account in batchS6S7AcData"
                      :key="account.s6_group_id"
                      :label="account.s6_group_name"
                      :value="account.s6_group_id"
                    >
                    </el-option>
                  </div>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="12" :offset="0">
              <el-form-item :label="__('Studio 7 Accounts')" prop="s7_ac_id">
                <el-select
                  value-key="s7_ac_id"
                  v-model="batchMappedS7AcIds"
                  :placeholder="__('Select')"
                  style="width: 100%"
                  @change="handleS7AcSelection"
                  filterable
                  :disabled="this.disableAc"
                  remote
                  multiple
                  :remote-method="getBatchS6S7AccountDataWithSearch"
                  default-first-option
                >
                  <div
                    class="infinite-list"
                    v-infinite-scroll="getBatchS6S7AccountData"
                  >
                    <el-option
                      v-for="account in batchMappedS7AcData"
                      :key="account.s7_ac_id"
                      :label="account.s7_ac_name"
                      :value="account.s7_ac_id"
                    >
                    </el-option>
                  </div>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" v-show="!this.acPlanExists">
            <el-col :span="12" :offset="0">
              {{
                __("The selected account does not have a plan assigned to it.")
              }}
            </el-col>
          </el-row>
          <el-row type="flex" v-show="this.acAlreadyMapped">
            {{ __("The selected account is already mapped.") }}
          </el-row>
        </template>
        <template v-else>
          <el-row type="flex">
            <el-col :span="12" :offset="0">
              <el-form-item :label="__('Studio 6 Account')" prop="s6_ac_id">
                <el-select
                  value-key="ac_id"
                  v-model="contentForm.ac_id"
                  :placeholder="__('Select')"
                  style="width: 100%"
                  @change="handleAcSelection"
                  filterable
                  remote
                  :remote-method="getS6GroupDataWithSearch"
                  default-first-option
                >
                  <div class="infinite-list" v-infinite-scroll="getS6GroupData">
                    <el-option
                      v-for="account in s6GroupData"
                      :key="account.group_id"
                      :label="account.group_name"
                      :value="account.group_id"
                    >
                    </el-option>
                  </div>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="12" :offset="0">
              <el-form-item :label="__('Studio 7 Account')" prop="ac_id">
                <el-select
                  value-key="ac_id"
                  v-model="mapped_s7_ac_id"
                  :placeholder="__('Select')"
                  style="width: 100%"
                  @change="handleS7AcSelection"
                  filterable
                  :disabled="this.disableAc"
                  remote
                  :remote-method="getS7AcDataWithSearch"
                  default-first-option
                >
                  <div class="infinite-list" v-infinite-scroll="getS7AcData">
                    <el-option
                      v-for="account in s7AcData"
                      :key="account.ac_id"
                      :label="account.ac_name"
                      :value="account.ac_id"
                    >
                    </el-option>
                  </div>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" v-show="!this.acPlanExists">
            <el-col :span="12" :offset="0">
              {{
                __("The selected account does not have a plan assigned to it.")
              }}
            </el-col>
          </el-row>
          <el-row type="flex" v-show="this.acAlreadyMapped">
            {{ __("The selected account is already mapped.") }}
          </el-row>
          <el-row type="flex" v-show="this.acFullyMigrated">
            <el-col :span="12" :offset="0">
              {{
                __(
                  "The selected account has been fully migrated to Studio 7 (S7_GA)"
                )
              }}
            </el-col>
          </el-row>
        </template>
      </div>
      <div v-show="step === 2">
        <el-card shadow="none">
          <el-row type="flex" style="padding: 5px 0 10px 0">
            <el-col :span="20" :offset="0">
              <span>
                {{ __("Account Validation") }}
                <div class="secondary-title" style="font-size: 0.8em;">
                  {{
                    __("Migration requires user input before it can continue")
                  }}
                </div>
              </span>
            </el-col>
            <el-col :span="5" :offset="10">
              <el-checkbox v-model="dev_mode">{{
                __("Developer Mode")
              }}</el-checkbox>
            </el-col>
          </el-row>
          <div>
            <div v-show="dev_mode">
              <el-input
                label="query"
                :placeholder="data"
                type="textarea"
                v-model="where_clause"
              ></el-input>
              <el-row type="flex" style="padding: 10px  0 10px 0">
                <el-col :span="6" :offset="0">
                  <el-input
                    size="small"
                    v-model="order_by"
                    placeholder="order by: asc or desc"
                  ></el-input>
                </el-col>
                <el-col :span="3" :offset="1">
                  <el-input
                    size="small"
                    v-model="limit"
                    placeholder="limit"
                  ></el-input>
                </el-col>
                <el-col :span="2" :offset="1">
                  <el-input
                    size="small"
                    v-model="skip"
                    placeholder="skip"
                  ></el-input>
                </el-col>
                <el-col :span="5" :offset="1">
                  <el-button
                    class="createBtn"
                    size="small"
                    @click="checkMigrationMap(null)"
                    >{{ __("Check Migration Map") }}
                  </el-button>
                </el-col>
                <el-col :span="5" :offset="1">
                  <el-button
                    class="createBtn"
                    size="small"
                    @click="checkMigrationMap('nodes_chunk')"
                    >{{ __("Check Nodes Chunk") }}
                  </el-button>
                </el-col>
              </el-row>
            </div>
            <div v-if="isBatchMigration" :key="componentKey">
              <el-alert
                v-if="showBatchMigrationTaskWarningMessage"
                title="Warning"
                type="warning"
                :description="batchMigrationTaskWarningMessage"
                show-icon
                class="mb-15"
              ></el-alert>
              <el-collapse v-model="batchGroupsActiveTabs">
                <el-collapse-item
                  v-for="batchMigrationS6Group in batchAccountContentValidation"
                  :key="batchMigrationS6Group.group_id"
                  :name="batchMigrationS6Group.group_id"
                  class="batch-migrate-group"
                >
                  <template slot="title">
                    <div>
                      {{ batchMigrationS6Group.group_name }}
                    </div>
                  </template>
                  <el-collapse
                    v-model="
                      batchActiveTabForGroup[batchMigrationS6Group.group_id]
                    "
                    accordion
                    class="batch-migrate-group-contents"
                  >
                    <el-collapse-item title="Tasks" name="1">
                      <batch-migrate-tasks-selector
                        @update:selectedTasks="
                          handleSelectTasksForBatchMigration(
                            $event,
                            batchMigrationS6Group.group_id
                          )
                        "
                        :taskData="
                          loadTasksForBatchMigration(batchMigrationS6Group)
                        "
                        :canSelectMore="!showBatchMigrationTaskWarningMessage"
                        :tasksAllowed="getBatchMigrationTasksAllowed"
                      ></batch-migrate-tasks-selector>
                    </el-collapse-item>
                    <el-collapse-item title="Contents" name="2">
                      <el-table
                        :data="
                          loadContentsForBatchMigration(batchMigrationS6Group)
                        "
                      >
                        <el-table-column
                          prop="table_name"
                          label="Table Name"
                          width="180"
                        ></el-table-column>
                        <el-table-column
                          prop="content_type"
                          label="Content Type"
                          width="180"
                        ></el-table-column>
                        <el-table-column
                          prop="message"
                          label="Message"
                          width="180"
                        ></el-table-column>
                        <el-table-column
                          prop="option_type"
                          label="Option Type"
                          width="180"
                        ></el-table-column>
                        <el-table-column
                          prop="user_option"
                          label="User Option"
                          width="180"
                        ></el-table-column>
                        <el-table-column
                          prop="default_option"
                          label="Default Option"
                          width="180"
                        ></el-table-column>
                        <el-table-column
                          prop="user_input"
                          label="User Input"
                          width="180"
                        ></el-table-column>
                      </el-table>
                    </el-collapse-item>
                  </el-collapse>
                </el-collapse-item>
              </el-collapse>
            </div>
            <div v-else>
              <el-table
                ref="userActionsTable"
                class="list-table"
                :data="this.accountContentValidation"
                @selection-change="handleCheckedTablesChange"
              >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column :label="__('Content Type')">
                  <template slot-scope="{ row }">
                    <div>
                      <span>{{ row.content_type }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column :label="__('Message')">
                  <template slot-scope="{ row }">
                    <div
                      v-if="row.option_type !== 'none'"
                      class="text-danger"
                      style="color: red;"
                    >
                      <span>{{ row.message }}</span>
                    </div>
                    <div v-else>
                      <span>{{ row.message }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column :label="__('Action')">
                  <template slot-scope="{ row }">
                    <div v-if="row.option_type === 'append_or_replace'">
                      <el-radio v-model="row.user_option" label="append"
                        >{{ __("Append text") }}
                      </el-radio>
                      <el-radio v-model="row.user_option" label="2"
                        >{{ __("Replace") }}
                      </el-radio>
                      <el-input
                        v-model="row.user_input"
                        :placeholder="__('enter text')"
                      ></el-input>
                    </div>
                    <div v-else-if="row.option_type === 'select'">
                      <el-select
                        v-model="row.user_option"
                        :placeholder="row.default_label"
                        filterable
                        default-first-option
                      >
                        <el-option
                          v-for="item in row.options"
                          :key="item.value"
                          :value="item.value"
                          :label="item.label"
                        ></el-option>
                      </el-select>
                    </div>
                    <div v-else-if="row.option_type === 'pick_items'">
                      <el-button
                        @click="
                          loadPickMigratedItemsDialog(
                            row.options,
                            row.table_name,
                            row.content_type
                          )
                        "
                      >
                        {{ __("Check Migrated Items") }}
                      </el-button>
                      <el-button
                        @click="
                          loadPickMigrationItemsDialog(
                            row.options,
                            row.table_name
                          )
                        "
                      >
                        {{ __("Pick Items to Migrate") }}
                      </el-button>
                      <el-button
                        @click="loadFlaggedNodesDialog"
                        :loading="flaggedNodesLoading"
                      >
                        {{ __("Flagged Nodes") }}
                      </el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-card>
        <el-form style="margin-top: 20px;">
          <el-row type="flex" :gutter="20" align="middle">
            <el-col :span="3">
              <label>{{ __("Tasks Per Batch") }}:</label>
            </el-col>
            <el-col :span="4">
              <el-input-number
                v-model="tasksPerBatch"
                :min="1"
                :max="20"
                :step="1"
                style="width: 100%;"
              ></el-input-number>
            </el-col>
            <el-col :span="6">
              <el-button
                type="primary"
                @click="handleAddToBatch"
                :loading="batchLoading"
              >
                {{ __("Add to Batch") }}
              </el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div v-show="step === 3 || step === 4 || step === 5">
        <div v-if="step === 3 && isBatchMigration" :key="componentKey">
          <div
            v-for="groupToMigrate in batchGroupsToMigrate"
            :key="groupToMigrate.group_id"
            class="batch-migrate-progress"
          >
            <el-row>
              <el-col :span="16">
                <div class="title pt-15">
                  {{ groupToMigrate.group_name }} (ID:
                  {{ groupToMigrate.group_id }})
                </div>
                <p class="subtitle">
                  Migration Job ID: {{ groupToMigrate.migration_job_uuid }}
                </p></el-col
              >
              <el-col :span="8">
                <div
                  v-if="
                    isBatchMigrationProgressAvailable(groupToMigrate.group_id)
                  "
                  class="pt-15"
                >
                  <el-checkbox
                    v-model="
                      batchMigrationProgress[groupToMigrate.group_id]
                        .show_details
                    "
                    >Show Details</el-checkbox
                  >
                </div>
                <div
                  v-if="
                    shouldShowBatchMigrationCheckStatusButton(
                      groupToMigrate.group_id
                    )
                  "
                >
                  <el-button
                    :loading="batchMigrationCheckingStatus"
                    type="primary"
                    plain
                    @click.prevent="
                      handleCheckBatchMigrationStatusButtonClick(groupToMigrate)
                    "
                    >{{
                      getCheckBatchMigrationStatusButtonLabel(
                        groupToMigrate.group_id
                      )
                    }}</el-button
                  >
                </div>
              </el-col>
            </el-row>

            <el-alert
              class="mb-15"
              v-if="
                batchMigrationProgress[groupToMigrate.group_id] &&
                  batchMigrationProgress[groupToMigrate.group_id]
                    .migration_failed
              "
              title="Migration failed"
              type="error"
              :description="
                batchMigrationProgress[groupToMigrate.group_id].migration_error
              "
              show-icon
            >
            </el-alert>

            <div class="mb-15">
              <el-progress
                :text-inside="true"
                :stroke-width="26"
                :percentage="
                  getBatchMigrationProgressPercentage(groupToMigrate.group_id)
                "
              ></el-progress>
            </div>

            <div
              v-if="
                batchMigrationProgress[groupToMigrate.group_id].percentage ===
                  100 &&
                  batchMigrationProgress[groupToMigrate.group_id]
                    .datastore_data_migration_started
              "
            >
              <div classs="subtitle pt-15 mb-15">Datastore Data Migration</div>
              <el-progress
                :text-inside="true"
                :stroke-width="26"
                :percentage="
                  getBatchDatastoredataMigrationProgressPercentage(
                    groupToMigrate.group_id
                  )
                "
              ></el-progress>
            </div>

            <div
              class="details"
              v-if="
                batchMigrationProgress[groupToMigrate.group_id].show_details
              "
            >
              <div
                v-for="(detail, index) in getBatchMigrationProgressDetails(
                  groupToMigrate.group_id
                )"
                :key="index"
              >
                <div :class="batchMigrationStatusClass(detail.status)">
                  <span>{{ detail.content_type }} </span>
                  <span v-if="detail.queue_id.length">
                    (Queue ID: {{ detail.queue_id }})
                  </span>
                  <span v-if="detail.status === 'Complete'">
                    migration completed.</span
                  >
                  <span v-if="detail.status === 'Failed'">
                    migration failed.</span
                  >
                  <span v-if="detail.status === 'Running'">
                    migration in progress.
                  </span>
                  <span
                    v-if="
                      !['Complete', 'Failed', 'Running'].includes(detail.status)
                    "
                  >
                    : {{ detail.status }}
                  </span>
                  <span v-if="detail.context">{{ detail.context }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template v-else>
          <div style="padding: 20px">
            <el-progress
              :text-inside="true"
              :stroke-width="20"
              :percentage="migrationCompletion"
              status="success"
            ></el-progress>
          </div>

          <el-table
            class="list-table"
            :data="process_data"
            row-key="item"
            lazy
            :load="loadDetails"
          >
            <el-table-column :label="__('Item')">
              <template slot-scope="{ row }">
                <div>
                  <span>{{ row.item }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="__('Result')">
              <template slot-scope="{ row }">
                <div style="color: green;" v-if="row.result === 'success'">
                  <span>{{ __("Success") }}</span>
                </div>
                <div style="color: red;" v-else>
                  <span>{{ __("Error") }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="__('Comment')">
              <template slot-scope="{ row }">
                <div style="color: red;">
                  <span>{{ row.comment }}</span>
                </div>
                <div style="color: green;" v-if="row.result === 'success'">
                  <span>{{ row.mapped_comment || "" }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="__('# in studio 6')">
              <template slot-scope="{ row }">
                <div>
                  <span>{{ row.s6_num }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="__('# in studio 7')">
              <template slot-scope="{ row }">
                <div>
                  <span>{{ row.s7_num }}</span>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div v-show="isMigrating" v-loading="isMigrating"></div>
        </template>
      </div>
    </el-form>

    <el-row
      type="flex"
      style="margin-top: 30px; padding-left: 0.7rem"
      v-if="step === 5 && !isBatchMigration"
    >
      <el-col
        :span="12"
        :offset="6"
        class="text-center"
        v-if="result === 'error'"
      >
        <el-button
          type="danger"
          class="w-full"
          @click="handleMigrationRollback"
          :loading="isSubmitting"
        >
          {{ __("Rollback") }}
        </el-button>
        <div style="font-size: 0.8em">
          {{ __("Need additional support?") }}
          <el-button type="text" @click="handleMigrationError">{{
            __("Report error to a developer")
          }}</el-button>
        </div>
      </el-col>
      <el-col
        :span="12"
        :offset="6"
        class="text-center"
        v-if="result === 'success' && migrationCompletion === 100"
      >
        <el-button
          v-if="contentForm.ac_id === -1 && !templatesPublished"
          type="primary"
          class="submitBtn"
          @click="publishMigratedTemplates"
          :loading="isSubmitting"
        >
          {{ __("Publish Migrated Templates") }}
        </el-button>
        <div style="font-size: 0.8em">
          <el-tooltip
            :disabled="this.accountData.allow_datastore_migration"
            :content="
              __(
                'This account does not have permission to migrate datastore, you can enable it in account profile'
              )
            "
          >
            <div style="display: inline-block;margin-right: 10px;">
              <el-button
                type="primary"
                class="submitBtn"
                @click="loadDatastoreMigrationDialog"
                :disabled="!this.accountData.allow_datastore_migration"
                >{{ __("Migrate Data Store Data") }}
              </el-button>
            </div>
          </el-tooltip>
          <el-button type="primary" class="submitBtn" @click="restart">{{
            __("Start Another Migration")
          }}</el-button>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" style="margin-top: 30px; padding-left: 0.7rem" v-else>
      <el-col :span="12" :offset="0">
        <el-button
          v-if="step === 0"
          type="primary"
          class="submitBtn"
          @click="validateTemplateContent"
          :loading="isSubmitting"
          >{{ __("Validate Template Content") }}
        </el-button>
        <el-button
          v-if="step === 0 && this.mappedS7Sp.length"
          type="primary"
          class="submitBtn"
          @click="nextStepBatchMigrateAccounts"
          :loading="isSubmitting"
          >{{ __("Migrate Multiple Accounts") }}
        </el-button>
        <el-button
          v-if="step > 0"
          type="primary"
          class="cancelBtn"
          @click="prevStep"
          :loading="isSubmitting"
          >{{ __("Previous Step") }}
        </el-button>
        <el-button
          v-if="step < 6"
          type="primary"
          class="submitBtn"
          @click="nextStep"
          :disabled="checkEmptyContent"
          :loading="isSubmitting"
        >
          {{ nextStepButtonText }}
        </el-button>

        <el-tooltip
          :disabled="this.accountData.allow_datastore_migration"
          :content="
            __(
              'This account does not have permission to migrate datastore, you can enable it in account profile'
            )
          "
        >
          <div style="display: inline-block;margin-left: 10px;">
            <el-button
              v-if="step === 2 && allowDataStoreMigration"
              type="primary"
              class="submitBtn"
              @click="loadDatastoreMigrationDialog"
              :loading="isSubmitting"
              :disabled="!this.accountData.allow_datastore_migration"
              >{{ __("Migrate Data Store Data") }}
            </el-button>
          </div>
        </el-tooltip>
        <el-button
          v-if="step > 2 && step < 6 && !isBatchMigration"
          type="primary"
          class="submitBtn"
          @click="retry"
        >
          {{ __("Retry") }}
        </el-button>
      </el-col>
    </el-row>
    <el-dialog
      :title="__('Pick Items to Migrate')"
      :visible.sync="pickMigrationItemsDialogVisible"
      :show-close="true"
      destroy-on-close
      width="50%"
    >
      <el-form>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-select
              v-model="selectedTaskType"
              :placeholder="__('Task Type')"
              @change="updateVisibleTasks"
              filterable
              default-first-option
            >
              <el-option
                v-for="item in taskTypes"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="6" :offset="6">
            <el-button @click="selectFirst10Items">Select First 10</el-button>
            <el-button @click="pickMigrationItemsDialogVisible = false">{{
              __("Add Items")
            }}</el-button>
          </el-col>
        </el-row>

        <el-table
          ref="migrationItemsTablesRef"
          :data="selectiveMigrationDataToMap"
          @selection-change="handleCheckedMigrationItemsChange"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column :label="__('Items')" prop="label"></el-table-column>
        </el-table>
      </el-form>
    </el-dialog>
    <el-dialog
      :title="__('Migrated Items')"
      :visible.sync="pickMigratedItemsDialogVisible"
      :show-close="true"
      destroy-on-close
      width="50%"
    >
      <el-select
        v-model="selectedTaskType"
        :placeholder="__('Task Type')"
        @change="updateVisibleMigratedTasks"
        filterable
        default-first-option
      >
        <el-option
          v-for="item in taskTypes"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>

      <el-table
        ref="migratedItemsTablesRef"
        :data="selectiveMigrationMappedData"
        @selection-change="handleCheckedMigratedItemsChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column :label="__('Items')" prop="label"></el-table-column>
      </el-table>

      <div style="margin-top: 15px; display: flex; align-items: center;">
        <el-button @click="selectiveRollback">{{ __("Rollback") }}</el-button>
        <el-button @click="handleFixLinkTaskNodes">{{
          __("Fix Link Task Nodes")
        }}</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="__('Datastore Migration Options')"
      :visible.sync="datastoreMigrationDialogVisible"
      :show-close="true"
      destroy-on-close
      width="50%"
    >
      <el-form v-loading="isLoading">
        <el-form-item label="Row number">
          <el-input-number
            v-model="numberOfDataStoreRowsToMigrate"
            type="number"
            :min="0"
            :step="5000"
            step-strictly
          ></el-input-number>
        </el-form-item>

        <el-table
          ref="datastoreMigrationTable"
          :data="datastoreMigrationList"
          @selection-change="handleDatastoreMigrationSelectionChange"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column
            :label="__('Name')"
            prop="s6_table_name"
          ></el-table-column>
        </el-table>

        <el-form-item style="margin-top: 20px">
          <el-button type="primary" @click="handleDatastoreMigrateButtonClick"
            >Migrate Datastore</el-button
          >
        </el-form-item>
        <div>
          Note: If row number is set to 0, all rows will be migrated. If no
          datastores are selected, all datastores will be migrated.
        </div>
      </el-form>
    </el-dialog>
    <el-dialog
      :title="__('Flagged Nodes')"
      :visible.sync="flaggedNodesDialogVisible"
      :show-close="true"
      destroy-on-close
      width="50%"
    >
      <el-table ref="flaggedNodesTable" :data="flaggedNodes">
        <el-table-column
          :label="__('Node ID')"
          prop="node_id"
        ></el-table-column>
        <el-table-column
          :label="__('Node Name')"
          prop="node_name"
        ></el-table-column>
        <el-table-column
          :label="__('Task ID')"
          prop="task_id"
        ></el-table-column>
      </el-table>
    </el-dialog>
    <migration-map
      :open-modal="isMapMode"
      :is-chunk="isChunk"
      @cancel="handleMapClose"
    ></migration-map>
  </div>
</template>

<script>
// import PageHeader from "@/components/PageHeader";
import { mapActions, mapState } from "vuex";
import {
  checkContentMigration,
  checkNodeMigration,
  checkCanvasesMigration,
  migrateDataStoreDataForAccount
} from "@/api/studio6accounts";
import _ from "lodash";
import MigrationMap from "@/views/system/migrate-account/pages/MigrationMap";
import { v4 as uuidv4 } from "uuid";
import UnmappedSpAc from "@/views/system/migrate-account/pages/UnmappedSpAc";
import BatchMigrateTasksSelector from "@/views/system/migrate-account/pages/BatchMigrateTasksSelector";
import {
  getS6ServiceProviderData,
  getS7ServiceProviderData,
  getS6GroupDataForSp,
  getS7AccountData
} from "@/api/studio6serviceproviders";
import { getSystemSettings } from "@/api/systemsettings";
import { MessageBox } from "element-ui";

export default {
  components: { UnmappedSpAc, MigrationMap, BatchMigrateTasksSelector },
  props: {},
  computed: {
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId
    }),

    ...mapState("spplans", {
      spPlanLoading: state => state.isLoading,
      spAssignedPlans: state => state.spPlans
    }),

    ...mapState("acplans", {
      acPlanLoading: state => state.isLoading,
      acAssignedPlans: state => state.availablePlans
    }),

    ...mapState("serviceproviders", {
      serviceProviders: state => state.serviceProviders,
      selectedSpAccounts: state => state.selectedSpAccounts,
      selectedSpTrunks: state => state.selectedSpTrunks,
      selectedSpApiKeys: state => state.selectedSpApiKeys,
      spLoading: state => state.loading
    }),
    ...mapState("studio6serviceproviders", {
      studio6serviceProviders: state => state.serviceProviders,
      studio6SelectedSpAccounts: state => state.serviceProviderAccounts,
      studio6spLoading: state => state.loading,
      accountContentValidation: state => state.accountContentValidation,
      batchAccountContentValidation: state =>
        state.batchAccountContentValidation,
      processData: state => state.processData,
      batchProcessData: state => state.batchProcessData,
      migrationErrors: state => state.migrationErrors,
      s6ServiceProviders: state => state.s6ServiceProviders,
      mappedS7Sp: state => state.mappedS7Sp,
      s6AccountsForSp: state => state.s6AccountsForSp,
      mappedS7Ac: state => state.mappedS7Ac,
      validatedSp: state => state.validatedSp,
      validatedAc: state => state.validatedAc,
      flaggedNodesLoading: state => state.flaggedNodesLoading
    }),
    ...mapState("passwordpolicies", {
      policies: state => state.policies,
      loading: state => state.isLoading
    }),
    ...mapState("tasklibrary", {
      templates: state => state.templates
    }),
    ...mapState("accounts", {
      loading: state => state.isLoading,
      accountData: state => state.accountData
    }),

    nextStepButtonText() {
      if (this.step === 1) {
        if (this.isBatchMigration) {
          return __("Select Tasks to Migrate");
        } else {
          return __("Validate account contents");
        }
      }
      if (this.step === 2) {
        if (this.isBatchMigration) {
          return __("Start Batch migration ->");
        } else {
          return __("Start Content migration ->");
        }
      }
      if (this.step === 3) {
        if (this.isBatchMigration) {
          return __("Done");
        } else {
          return __("Start Nodes migration ->");
        }
      }
      if (this.step === 4 && !this.isBatchMigration) {
        return __("Start Canvases migration ->");
      }
      if (this.step === 5 && !this.isBatchMigration) {
        return __("Done");
      }
      return __("Next Step");
    },

    disableSp() {
      return !_.isEmpty(this.mappedS7Sp) || this.contentForm.s6_sp_id === "";
    },

    disableAc() {
      return !_.isEmpty(this.mappedS7Ac) || this.contentForm.ac_id === "";
    },

    spPlan() {
      if (!_.isEmpty(this.mappedS7Sp) && this.contentForm.sp_id !== "") {
        return this.mappedS7Sp[0].sp_plan_exists;
      }
      return true;
    },

    acPlan() {
      if (!_.isEmpty(this.mappedS7Ac) && this.contentForm.ac_id !== "") {
        return this.mappedS7Ac[0].ac_plan_exists;
      }
      return true;
    },

    acAlreadyMapped() {
      if (!_.isEmpty(this.validatedAc) && this.mapped_s7_ac_id !== null) {
        return this.validatedAc.data.already_mapped;
      }
      return false;
    },

    acPlanExists() {
      if (!_.isEmpty(this.validatedAc) && this.mapped_s7_ac_id !== null) {
        return this.validatedAc.data.sp_plan_exists;
      }
      return true;
    },

    spAlreadyMapped() {
      if (!_.isEmpty(this.validatedSp) && this.mapped_s7_sp_id !== null) {
        return this.validatedSp.data.already_mapped;
      }
      return false;
    },

    acFullyMigrated() {
      if (!_.isEmpty(this.validatedAc) && this.mapped_s7_ac_id !== null) {
        return this.validatedAc.data.fully_migrated;
      }
      return false;
    },

    spPlanExists() {
      if (!_.isEmpty(this.validatedSp) && this.mapped_s7_sp_id !== null) {
        return this.validatedSp.data.sp_plan_exists;
      }
      return true;
    },

    checkEmptyContent() {
      if (this.step === 2) {
        if (this.isBatchMigration) {
          return _.isEmpty(this.batchGroupsToMigrate);
        } else {
          return _.isEmpty(this.checkedTables);
        }
      }
      return false;
    },

    showBatchMigrationAcWarningMessage() {
      return (
        this.contentForm.batch_s6_ac_ids.length >
          this.batchMigrationAccountsWarningLimit &&
        this.contentForm.batch_s6_ac_ids.length <=
          this.batchMigrationAccountsMaxLimit
      );
    },

    batchMigrationAcWarningMessage() {
      return `You have selected more than ${this.batchMigrationAccountsWarningLimit} accounts. The maximum allowed is ${this.batchMigrationAccountsMaxLimit}. Please be aware that selecting more than ${this.batchMigrationAccountsWarningLimit} may cause migration issues.`;
    },

    batchMigrationTotalSelectedTasks() {
      return Object.values(this.batchGroupsToMigrate).reduce((acc, group) => {
        return acc + (group.tasks ? group.tasks.length : 0);
      }, 0);
    },

    showBatchMigrationTaskWarningMessage() {
      return (
        this.batchMigrationTotalSelectedTasks >=
        this.batchMigrationTasksMaxLimit
      );
    },

    getBatchMigrationTasksAllowed() {
      return (
        this.batchMigrationTasksMaxLimit - this.batchMigrationTotalSelectedTasks
      );
    },

    batchMigrationTaskWarningMessage() {
      return `The maximum allowed task count is ${this.batchMigrationTasksMaxLimit}.`;
    }
  },
  data() {
    const checkFromSpId = (rule, value, callback) => {
      if (this.contentForm.sp_id === "") {
        callback(__("Service provider is required"));
      } else {
        callback();
      }
    };
    const checkToSpId = (rule, value, callback) => {
      if (this.contentForm.to_sp_id === "") {
        callback(__("Service provider is required"));
      } else {
        callback();
      }
    };
    const checkAcId = (rule, value, callback) => {
      if (this.contentForm.ac_id === "") {
        callback(__("Account is required"));
      } else {
        callback();
      }
    };
    return {
      step: 0,
      isMapMode: false,
      isChunk: false,
      migrationCompletion: 0,
      checkedTables: [],
      result: "success",
      contentForm: {
        sp_id: "",
        to_sp_id: "",
        ac_id: "",
        s6_sp_id: "",
        batch_s6_ac_ids: []
      },
      data:
        __("enter your eloquent query like") +
        ' - [{"operation":"json_contains","column":"mapping_helper_json","value":\'{"s6_pk":1121}\'}] ' +
        __("or") +
        ' [{"operation":"whereIn","column":"s6_group_id","value":\'[\'61\']\'}]',
      dev_mode: false,
      where_clause: "",
      order_by: "desc",
      limit: 500,
      skip: 0,
      process_data: [],
      defaultOption: "merge",
      btnLoading: false,
      isLoading: false,
      isMigrating: false,
      isRollingBack: false,
      isSubmitting: false,
      templatesPublished: false,
      allowDataStoreMigration: false,
      activeSection: "ac-plans",
      numberOfDataStoreRowsToMigrate: 0,
      rules: {
        sp_id: [
          {
            required: true,
            trigger: "blur",
            message: __("Service Provider is required"),
            validator: checkFromSpId
          }
        ],
        ac_id: [
          {
            required: true,
            trigger: "blur",
            message: __("Account is required"),
            validator: checkAcId
          }
        ],
        to_sp_id: [
          {
            required: true,
            trigger: "blur",
            message: __("Service Provider is required"),
            validator: checkToSpId
          }
        ]
      },
      nodeChunksMigrationResults: [],
      componentKey: 0,

      pickMigrationItemsDialogVisible: false,
      pickMigrationItemTableName: "",
      selectiveMigrationDataToMap: [],
      checkedMigrationItems: {},
      selectedTaskType: "SCRIPT",
      availableTasks: [],
      taskTypes: [],

      pickMigratedItemsDialogVisible: false,
      pickMigratedItemTableName: "",
      pickMigratedItemContentType: "",
      selectiveMigrationMappedData: [],
      checkedMigratedItems: {},

      datastoreMigrationDialogVisible: false,
      datastoreMigrationList: [],
      checkedDataStoreMigrationItems: {},

      currentMigrationStart: 0,
      currentMigrationStop: 0,

      stepToMigrateNodes: 18,
      migrationJobId: "",
      migration_errors: [],
      showMigrationErrors: false,
      migrationErrorDialogVisible: false,
      errorsLoading: false,
      mapped_s7_sp_id: null,
      s7_sp_id: 0,
      mapped_s7_ac_id: null,
      s7_ac_id: 0,
      unmappedItemsLoading: false,
      unmappedItemsDialogVisible: false,
      s6SpData: [],
      s6SpPage: 0,
      s6SpCount: 30,
      loadSpData: true,
      s6SpDataLoading: false,
      s7SpData: [],
      s7SpDataLoading: false,
      s7SpPage: 0,
      s7SpCount: 30,
      loadS7SpData: true,
      s6GroupData: [],
      s6GroupPage: 0,
      s6GroupCount: 30,
      s6GroupDataLoading: true,
      loadS6GroupData: true,
      s7AcData: [],
      s7AcDataLoading: false,
      s7AcPage: 0,
      s7AcCount: 30,
      loadS7AcData: true,
      flaggedNodesDialogVisible: false,
      flaggedNodes: [],
      isBatchMigration: false,
      batchS6S7AcData: [],
      batchMappedS7AcIds: [],
      batchMappedS7AcData: [],
      batchGroupsToMigrate: {},
      batchGroupsActiveTabs: [],
      batchActiveTabForGroup: {},
      batchMigrationProgress: {},
      batchMigrationAccountsWarningLimit: 5,
      batchMigrationAccountsMaxLimit: 10,
      batchMigrationTasksMaxLimit: 50,
      batchMigrationCheckingStatus: false,
      tasksPerBatch: 3,
      batchLoading: false
    };
  },
  methods: {
    ...mapActions("serviceproviders", {
      fetchAccountsForSelectedSP: "fetchAccountsForSelectedSP",
      getServiceProviders: "getServiceProviders",
      fetchTasksForSelectedAc: "fetchTasksForSelectedAc",
      fetchTrunksForSelectedSP: "fetchTrunksForSelectedSP",
      getSpApiKeys: "getSpApiKeys",
      moveAccountToSp: "moveAccountToSp"
    }),
    ...mapActions("studio6serviceproviders", {
      fetchAccountsForSelectedStudio6SP: "fetchAccountsForSelectedSP",
      validateAccountContent: "validateAccountContent",
      batchValidateAccountContent: "batchValidateAccountContent",
      migrateAccountContent: "migrateAccountContent",
      batchMigrateAccountContent: "batchMigrateAccountContent",
      rollbackAccountContent: "rollbackAccountContent",
      reportMigrationError: "reportMigrationError",
      rollbackAccountTable: "rollbackAccountTable",
      getMigrationMap: "getMigrationMap",
      consolidateNodeMigrationProgress: "consolidateNodeMigrationProgress",
      publishTemplates: "publishTemplates",
      checkMigrationErrors: "checkMigrationErrors",
      getS6ServiceProviders: "getS6ServiceProviders",
      getMappedS7Sp: "getMappedS7Sp",
      mapS6SpToS7Sp: "mapS6SpToS7Sp",
      getS6AccountsForSp: "getS6AccountsForSp",
      getMappedS7Ac: "getMappedS7Ac",
      mapS6AcToS7Ac: "mapS6AcToS7Ac",
      validateSelectedSp: "validateSelectedSp",
      validateSelectedAc: "validateSelectedAc",
      getUnmappedServiceProviders: "getUnmappedServiceProviders",
      getUnmappedAccounts: "getUnmappedAccounts",
      getAccountsAvailableForRemapping: "getAccountsAvailableForRemapping",
      getDataStoreListForAccount: "getDataStoreListForAccount",
      getFlaggedNodesForAccount: "getFlaggedNodesForAccount",
      getBatchS6S7AccountDataForSp: "getBatchS6S7AccountDataForSp",
      fixLinkTaskNodesForAccount: "fixLinkTaskNodesForAccount",
      addBatchMigration: "addBatchMigration"
    }),
    ...mapActions("acplans", {
      getAcPlansForSelectedAccount: "getAcPlansForSelectedAccount",
      getAccountsForServiceProvider: "getAccountsForServiceProvider"
    }),
    ...mapActions("spplans", {
      getSpPlans: "getSpPlans"
    }),
    ...mapActions("passwordpolicies", {
      getPasswordPolicies: "getPasswordPolicies"
    }),
    ...mapActions("tasklibrary", {
      fetchTemplatesForSp: "fetchTemplatesForSp"
    }),
    ...mapActions("accounts", {
      getAccountData: "getAccountData"
    }),
    getS6SpData(query = "") {
      if (this.loadSpData) {
        this.s6SpDataLoading = true;
        getS6ServiceProviderData({
          page: this.s6SpPage,
          count: this.s6SpCount,
          query: query
        })
          .then(data => {
            let spData = data.data.data;
            if (!_.isEmpty(spData)) {
              this.s6SpData = [...this.s6SpData, ...spData];
              this.s6SpPage += 1;
            }
            this.loadSpData =
              _.isEmpty(spData) || spData.length === this.s6SpCount;
            this.s6SpDataLoading = false;
          })
          .catch(err => {
            console.log(err);
            this.s6SpDataLoading = false;
          });
      }
    },

    getS6GroupData(query = "") {
      if (this.loadS6GroupData && this.contentForm.s6_sp_id !== "") {
        this.s6GroupDataLoading = true;
        getS6GroupDataForSp({
          service_provider_id: this.contentForm.s6_sp_id,
          page: this.s6GroupPage,
          count: this.s6GroupCount,
          query: query
        })
          .then(data => {
            let groupData = data.data.data;
            if (!_.isEmpty(groupData)) {
              this.s6GroupData = [...this.s6GroupData, ...groupData];
              this.s6GroupPage += 1;
            }
            this.loadS6GroupData =
              _.isEmpty(groupData) || groupData.length === this.s6GroupCount;
            this.s6GroupDataLoading = false;
          })
          .catch(err => {
            console.log(err);
            this.s6GroupDataLoading = false;
          });
      }
    },

    getS7SpData(query = "") {
      if (this.loadS7SpData) {
        this.s7SpDataLoading = true;
        getS7ServiceProviderData({
          page: this.s7SpPage,
          count: this.s7SpCount,
          query: query
        })
          .then(data => {
            let spData = data.data.data;
            if (!_.isEmpty(spData)) {
              this.s7SpData = [...this.s7SpData, ...spData];
              this.s7SpPage += 1;
            }
            this.loadS7SpData =
              _.isEmpty(spData) || spData.length === this.s7SpCount;
            this.s7SpDataLoading = false;
          })
          .catch(err => {
            console.log(err);
            this.s7SpDataLoading = false;
          });
      }
    },

    getS7AcData(query = "") {
      if (this.loadS7AcData) {
        this.s7AcDataLoading = true;
        getS7AccountData({
          page: this.s7AcPage,
          count: this.s7AcCount,
          mapped_s7_sp_id: this.mapped_s7_sp_id,
          query: query
        })
          .then(data => {
            let acData = data.data.data;
            if (!_.isEmpty(acData)) {
              this.s7AcData = [...this.s7AcData, ...acData];
              this.s7AcPage += 1;
            }
            this.loadS7AcData =
              _.isEmpty(acData) || acData.length === this.s7AcCount;
            this.s7AcDataLoading = false;
          })
          .catch(err => {
            console.log(err);
            this.s7AcDataLoading = false;
          });
      }
    },

    handleCheckedTablesChange(value) {
      this.checkedTables = value;
    },
    handleMapClose() {
      this.isMapMode = !this.isMapMode;
    },

    getS6SpDataWithSearch(query) {
      this.s6SpData = [];
      this.s6SpPage = 0;
      this.loadSpData = true;
      this.getS6SpData(query);
    },

    getS6GroupDataWithSearch(query) {
      this.s6GroupData = [];
      this.s6GroupPage = 0;
      this.loadS6GroupData = true;
      this.getS6GroupData(query);
    },

    getS7SpDataWithSearch(query) {
      this.s7SpData = [];
      this.s7SpPage = 0;
      this.loadS7SpData = true;
      this.getS7SpData(query);
    },

    getS7AcDataWithSearch(query) {
      this.s7AcData = [];
      this.s7AcPage = 0;
      this.loadS7AcData = true;
      this.getS7AcData(query);
    },

    async checkMigrationMap(table_name = null) {
      await this.getMigrationMap({
        table_name:
          table_name === null ? this.checkedTables[0].table_name : table_name,
        group_id: this.contentForm.ac_id,
        where: this.where_clause,
        order: this.order_by,
        skip: this.skip,
        limit: this.limit
      });
      if (table_name === null) {
        this.isChunk = false;
      } else {
        this.isChunk = true;
      }
      this.isMapMode = true;
    },

    async validateTemplateContent() {
      if (this.step === 0) {
        this.btnLoading = true;
        this.isSubmitting = true;
        this.contentForm.ac_id = -1;
        this.migrationJobId = uuidv4();
        await this.validateAccountContent({
          group_id: this.contentForm.ac_id,
          migration_job_uuid: this.migrationJobId
        }).then(data => {
          this.stepToMigrateNodes = _.findIndex(data, { table_name: "nodes" });
        });
        this.btnLoading = false;
        this.isSubmitting = false;
        this.step = 2;
      }
    },

    async publishMigratedTemplates() {
      if (this.contentForm.ac_id === -1 && this.result === "success") {
        this.btnLoading = true;
        this.isSubmitting = true;
        await this.publishTemplates()
          .then(data => {
            this.$message({
              type: "success",
              message: data.message
            });
          })
          .catch(() => {
            this.$message({
              type: "error",
              message: __("Publishing migrated templates failed.")
            });
          })
          .then(() => {
            this.btnLoading = false;
            this.isSubmitting = false;
            this.templatesPublished = true;
          })
          .catch(() => {});
      }
    },

    async nextStep() {
      let goToNextStep = true;
      this.allowDataStoreMigration = false;
      if (this.step === 0) {
        if (_.isEmpty(this.mappedS7Sp)) {
          await this.handleMapServiceProvider();
          this.mapped_s7_ac_id = null;
        }

        if (this.isBatchMigration) {
          await this.getBatchS6S7AccountData();
        } else {
          await this.getS6GroupData();
        }
      }

      if (this.step === 1) {
        if (this.isBatchMigration) {
          if (
            !_.isEmpty(this.batchMappedS7AcData) &&
            this.contentForm.batch_s6_ac_ids.length > 0
          ) {
            await this.handleBatchValidateAccountContent();
          } else {
            goToNextStep = false;
            this.$message({
              type: "error",
              message: "Please select accounts to migrate."
            });
          }
        } else {
          if (_.isEmpty(this.mappedS7Ac)) {
            goToNextStep = false;
            await this.handleMapAccount();
            return;
          }

          if (this.contentForm.ac_id > 0) {
            const result = await this.handleAccountValidation();
            goToNextStep = result;
          }
        }
      }

      if (this.step === 2) {
        if (this.isBatchMigration) {
          if (this.batchGroupsToMigrate) {
            this.step++;
            await this.handleBatchMigrateAccountContent();
            return;
          } else {
            this.$message({
              type: "error",
              message: "Please select groups to migrate."
            });
            return;
          }
        } else {
          if (this.contentForm.ac_id > 0 || this.contentForm.ac_id === -1) {
            this.resetMigration();
            this.step++;
            await this.migrateContent();
            return;
          }
        }
      }

      if (this.step === 3) {
        if (this.isBatchMigration) {
          this.restart();
          this.cancelBatchMigration();
        } else {
          if (this.contentForm.ac_id > 0 || this.contentForm.ac_id === -1) {
            await this.handleContentMigration();
          }
        }
        return;
      }

      if (
        this.step === 4 &&
        (this.contentForm.ac_id > 0 || this.contentForm.ac_id === -1) &&
        !this.isBatchMigration
      ) {
        await this.handleNodeMigration();
        return;
      }
      if (goToNextStep) {
        this.step++;
      }
    },

    async migrateDataStoreData() {
      MessageBox.confirm(
        "Are you sure you want to start datastore migration?",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning"
        }
      ).then(async () => {
        await migrateDataStoreDataForAccount({
          group_id: this.contentForm.ac_id,
          migration_job_uuid: this.migrationJobId,
          dataStoreIds: this.checkedDataStoreMigrationItems,
          rowCount: Number(this.numberOfDataStoreRowsToMigrate)
        })
          .then(() => {
            this.$message({
              type: "success",
              message: __("Data store data migration triggered successfully.")
            });
          })
          .catch(err => {
            console.log(err);
          });
      });
    },

    async triggerDataStoreDataMigration() {
      MessageBox.confirm(
        "Are you sure you want to start datastore migration?",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning"
        }
      ).then(async () => {
        await checkCanvasesMigration({
          group_id: this.contentForm.ac_id,
          migration_job_uuid: this.migrationJobId
        }).then(async data => {
          if (data.data.message) {
            await migrateDataStoreDataForAccount({
              group_id: this.contentForm.ac_id,
              migration_job_uuid: this.migrationJobId,
              dataStoreIds: this.checkedDataStoreMigrationItems,
              rowCount: Number(this.numberOfDataStoreRowsToMigrate)
            })
              .then(() => {
                this.$message({
                  type: "success",
                  message: __(
                    "Data store data migration triggered successfully."
                  )
                });
              })
              .catch(err => {
                console.log(err);
              });
          } else {
            this.$message({
              type: "error",

              // eslint-disable-next-line
            message: __("Canvases are still in progress in background, :total_migrated / :canvases canvases",
                {
                  total_migrated: data.data.total_canvases_migrated,
                  canvases: data.data.total_canvases
                }
              )
            });
          }
        });
      });
    },

    async viewUnmappedItems() {
      this.unmappedItemsLoading = true;
      this.contentForm.s6_sp_id = "";
      this.mapped_s7_sp_id = "";
      await this.getUnmappedServiceProviders();
      await this.getUnmappedAccounts();
      this.unmappedItemsLoading = false;
      this.unmappedItemsDialogVisible = true;
    },

    prevStep() {
      if (this.isBatchMigration) {
        this.step--;
        if (this.step === 0) {
          this.loadS6GroupData = true;
          this.cancelBatchMigration();
        } else if (this.step === 1) {
          this.batchGroupsToMigrate = {};
          this.componentKey++;
        } else if (this.step === 2) {
          this.batchMigrationProgress = {};
          for (let groupToMigrate of this.batchGroupsToMigrate) {
            this.$set(
              this.batchGroupsToMigrate[groupToMigrate.group_id],
              "tasks",
              []
            );
          }
          this.componentKey++;
        }
      } else {
        if (this.contentForm.ac_id === -1 && this.step === 2) {
          this.contentForm.ac_id = "";
          this.step = 0;
        } else {
          this.step--;
        }

        if (this.step === 0) {
          this.loadS6GroupData = true;
        }
      }
    },
    restart() {
      this.step = 0;
      this.loadSpData = true;
      this.loadS7SpData = true;
      this.loadS6GroupData = true;
    },

    async migrateContent(retry = false) {
      this.isMigrating = true;
      this.isSubmitting = true;
      let tables_to_include = this.checkedTables
        .filter(item => item.table_name)
        .map(item => item.table_name);
      let start = 0;
      let stop = this.stepToMigrateNodes - 1;
      if (this.step === 4) {
        start = this.stepToMigrateNodes;
        stop = this.stepToMigrateNodes;
      }
      if (this.step === 5) {
        start = this.stepToMigrateNodes + 1;
        stop = this.stepToMigrateNodes + 1;
      }

      if (retry === true) {
        start = this.currentMigrationStart;
        stop = this.currentMigrationStop;
      }

      for (let step = start; step <= stop; step++) {
        if (this.result === "error") break;
        this.currentMigrationStop = stop;
        this.currentMigrationStart = step;

        await this.migrateAccountContent({
          options: this.accountContentValidation,
          unmapped_instances: _.find(this.accountContentValidation, {
            content_type: "Instances"
          })["unmapped_instances"],
          group_id: this.contentForm.ac_id,
          migration_step: step,
          migration_job_uuid: this.migrationJobId,
          items_to_include: this.checkedMigrationItems,
          tables_to_include: tables_to_include
        })
          .then(() => {
            this.process_data.push(this.processData);
          })
          .catch(err => {
            console.log(err);
            this.step--;
            this.$message({
              type: "error",
              message: err.response.data.message
            });
          });

        if (this.processData.result === "error") {
          this.result = "error";
        } else {
          this.migrationJobId = this.processData.migration_job_uuid;

          if (
            step === this.stepToMigrateNodes - 1 &&
            !tables_to_include.includes("tasks")
          ) {
            this.migrationCompletion = 100;
            step = this.stepToMigrateNodes + 2;
            this.currentMigrationStart = step;
            this.currentMigrationStop = step;
            this.step = 5;

            break;
          }

          if (step === this.stepToMigrateNodes + 1 && this.migrationJobId) {
            this.$echo
              .channel("canvas_migration_failed." + this.migrationJobId)
              .listen("CanvasMigrationFailedEvent", eventData => {
                if (eventData && eventData.error) {
                  let lastIndex = this.process_data.length - 1;
                  this.$set(this.process_data[lastIndex], "result", "error");
                  this.$set(
                    this.process_data[lastIndex],
                    "comment",
                    this.migrationJobId
                  );
                  console.log(eventData);
                }
              });
          }

          this.migrationCompletion = Math.round(
            ((step + 1) / this.accountContentValidation.length) * 100
          );
        }
      }
      this.isMigrating = false;
      this.isSubmitting = false;
    },

    resetMigration() {
      this.process_data = [];
      this.migrationCompletion = 0;
      this.result = "success";
      this.templatesPublished = false;
      this.allowDataStoreMigration = false;
    },

    handleMigrationRollback() {
      this.$confirm(__("Are you sure you want to perform rollback?"), {
        confirmButtonText: __("OK"),
        cancelButtonText: __("Cancel"),
        type: "warning"
      })
        .then(() => {
          this.isLoading = true;
          this.rollbackAccountContent({
            group_id: this.contentForm.ac_id
          })
            .then(data => {
              this.$message({
                type: "success",
                message: data.message
              });
            })
            .catch(() => {
              this.$message({
                type: "error",
                message: __("Rollback Unsuccessful")
              });
            })
            .then(() => {
              this.step = 0;
              this.isLoading = false;
            });
        })
        .catch(() => {});
    },

    handleMigrationError() {
      this.isLoading = true;
      this.reportMigrationError({
        group_id: this.contentForm.ac_id,
        sp_id: this.contentForm.sp_id,
        migration_data: this.process_data
      })
        .then(data => {
          this.$message({
            type: "success",
            message: data.message
          });
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: __("Reporting migration error failed.")
          });
        })
        .then(() => {
          this.step = 0;
          this.isLoading = false;
        })
        .catch(() => {});
    },

    async handleSpSelection(sp) {
      this.s7_sp_id = sp;
      await this.validateSelectedSp(sp);
    },

    async handleS7AcSelection(ac) {
      this.s7_ac_id = ac;
      await this.validateSelectedAc(ac);
    },

    async handleToSpSelection(sp) {
      this.s7SpData = [];
      this.s6GroupData = [];
      this.batchS6S7AcData = [];
      this.mapped_s7_ac_id = null;
      this.contentForm.ac_id = "";
      this.s7SpPage = 0;
      this.s7AcPage = 0;
      this.s6GroupPage = 0;
      this.loadS7SpData = true;
      this.s7SpDataLoading = true;
      await this.getMappedS7Sp(sp);
      if (!_.isEmpty(this.mappedS7Sp)) {
        this.mapped_s7_sp_id = this.mappedS7Sp[0].mapped_s7_sp_id;
        this.s7SpData = getS7ServiceProviderData({
          mapped_s7_sp_id: this.mapped_s7_sp_id
        })
          .then(data => {
            this.s7SpData = data.data.data;
            this.s7SpDataLoading = false;
          })
          .catch(err => {
            console.log(err);
            this.s7SpDataLoading = false;
          });
      } else {
        this.mapped_s7_sp_id = null;
        this.getS7SpData();
      }
    },

    async handleAcSelection(ac) {
      this.s7AcData = [];
      this.s7AcPage = 0;
      this.loadS7AcData = true;
      this.s7AcDataLoading = true;
      await this.getMappedS7Ac(ac);
      if (!_.isEmpty(this.mappedS7Ac)) {
        this.mapped_s7_ac_id = this.mappedS7Ac[0].mapped_s7_ac_id;
        this.s7AcData = getS7AccountData({
          mapped_s7_ac_id: this.mapped_s7_ac_id
        })
          .then(data => {
            this.s7AcData = data.data.data;
            this.s7AcDataLoading = false;
          })
          .catch(err => {
            console.log(err);
            this.s7AcDataLoading = false;
          });
      } else {
        this.mapped_s7_ac_id = null;
        this.getS7AcData();
      }
    },

    initContent() {
      this.contentForm.sp_id = "";
      this.contentForm.to_sp_id = "";
      this.contentForm.ac_id = "";
    },
    handleCancel() {
      this.initContent();
      this.$emit("cancel");
    },
    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true;
          this.moveAccountToSp(this.contentForm)
            .then(data => {
              this.isSubmitting = false;
              this.$message({
                name: "success",
                message: data.message
              });
            })
            .catch(err => {
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.response.data.message
              });
            });
        }
      });
    },

    loadDetails(tree, treeNode, resolve) {
      if (this.step === 4) {
        setTimeout(() => {
          resolve(this.nodeChunksMigrationResults);
        }, 1000);
      }
    },

    loadPickMigrationItemsDialog(options, tableName) {
      this.pickMigrationItemsDialogVisible = true;
      this.pickMigrationItemTableName = tableName;
      this.selectedTaskType = "SCRIPT";
      this.taskTypes = _.get(options, "task_types");
      this.selectiveMigrationDataToMap = _.get(
        options,
        "data_to_map." + this.selectedTaskType
      );
      this.availableTasks = _.cloneDeep(options);
    },

    async loadFlaggedNodesDialog() {
      await this.getFlaggedNodesForAccount({
        group_id: this.contentForm.ac_id
      })
        .then(data => {
          this.flaggedNodes = data.data;
          this.isLoading = false;
          this.flaggedNodesDialogVisible = true;
        })
        .catch(err => {
          console.log(err);
          this.isLoading = false;
          this.$message({
            type: "error",
            message: err.message
          });
        });
    },

    updateVisibleTasks() {
      this.selectiveMigrationDataToMap = _.get(
        this.availableTasks,
        "data_to_map." + this.selectedTaskType
      );
    },

    updateVisibleMigratedTasks() {
      this.selectiveMigrationMappedData = _.get(
        this.availableTasks,
        "mapped_data." + this.selectedTaskType
      );
    },

    handleCheckedMigrationItemsChange(value) {
      if (this.pickMigrationItemTableName) {
        _.set(
          this.checkedMigrationItems,
          this.pickMigrationItemTableName,
          _.cloneDeep(value)
        );
      }
    },

    handleDatastoreMigrationSelectionChange(value) {
      const filteredData = _.map(value, obj => _.pick(obj, ["s7_pk"]));
      this.checkedDataStoreMigrationItems = filteredData;
    },

    loadPickMigratedItemsDialog(options, tableName, contentType) {
      this.pickMigratedItemsDialogVisible = true;
      this.pickMigratedItemTableName = tableName;
      this.pickMigratedItemContentType = contentType.toLowerCase();
      this.selectedTaskType = "SCRIPT";
      this.taskTypes = _.get(options, "task_types");
      this.selectiveMigrationMappedData = _.get(
        options,
        "mapped_data." + this.selectedTaskType
      );
      this.availableTasks = _.cloneDeep(options);
    },

    async loadDatastoreMigrationDialog() {
      this.datastoreMigrationDialogVisible = true;
      this.isLoading = true;
      await this.getDataStoreListForAccount({
        group_id: this.contentForm.ac_id
      })
        .then(data => {
          this.datastoreMigrationList = data.data;
          this.isLoading = false;
        })
        .catch(err => {
          console.log(err);
          this.isLoading = false;
          this.$message({
            type: "error",
            message: err.message
          });
        });
    },

    handleCheckedMigratedItemsChange(value) {
      if (this.pickMigratedItemTableName) {
        _.set(
          this.checkedMigratedItems,
          this.pickMigratedItemTableName,
          _.cloneDeep(value)
        );
      }
    },

    handleDatastoreMigrateButtonClick() {
      if (this.step === 2) {
        this.migrateDataStoreData();
      }
      if (this.step === 5) {
        this.triggerDataStoreDataMigration();
      }
    },

    async selectiveRollback() {
      await this.rollbackAccountTable({
        group_id: this.contentForm.ac_id,
        items_to_include: this.checkedMigratedItems
      }).then(data => {
        this.$message({
          type: "info",
          message: data.message
        });
        this.pickMigratedItemsDialogVisible = false;
      });
    },

    selectFirst10Items() {
      if (this.pickMigrationItemTableName) {
        this.$nextTick(() => {
          for (let i = 0; i < 10; i++) {
            this.$refs.migrationItemsTablesRef.toggleRowSelection(
              this.selectiveMigrationDataToMap[i],
              true
            );
          }
        });
      }
    },

    async retry() {
      await this.migrateContent(true);
    },

    async checkMigrationError() {
      this.errorsLoading = true;
      await this.checkMigrationErrors({
        migration_job_uuid: this.migrationJobId
      }).then(data => {
        this.migration_errors = data.errors;
        this.errorsLoading = false;
        this.showMigrationErrors = true;
      });
    },

    async handleMapServiceProvider() {
      this.$confirm(__("Map Service Provider?"), {
        confirmButtonText: __("OK")
      })
        .then(async () => {
          await this.mapS6SpToS7Sp({
            s6_sp_id: this.contentForm.s6_sp_id,
            s7_sp_id: this.s7_sp_id
          })
            .then(async () => {
              this.$message({
                type: "success",
                message: __("Service Provider mapped successfully.")
              });
              await this.getMappedS7Sp(this.contentForm.s6_sp_id);
            })
            .catch(err => {
              console.log(err);
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        })
        .catch(() => {});
    },

    async handleMapAccount() {
      this.$confirm(
        __("Accounts cannot be remapped once any content has been migrated."),
        __("Map Account?"),
        {
          confirmButtonText: __("OK")
        }
      )
        .then(async () => {
          await this.mapS6AcToS7Ac({
            s6_ac_id: this.contentForm.ac_id,
            s7_ac_id: this.s7_ac_id
          })
            .then(async () => {
              this.s6SpDataLoading = false;
              this.$message({
                type: "success",
                message: __("Account mapped successfully.")
              });
              await this.getMappedS7Ac(this.contentForm.ac_id);
            })
            .catch(err => {
              console.log(err);
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        })
        .catch(() => {});
    },

    async handleAccountValidation() {
      this.allowDataStoreMigration = false;
      this.btnLoading = true;
      this.isSubmitting = true;
      this.migrationJobId = uuidv4();

      let success = true;
      try {
        await this.getAccountData(this.mapped_s7_ac_id);
        await this.validateAccountContent({
          group_id: this.contentForm.ac_id,
          migration_job_uuid: this.migrationJobId
        })
          .then(async data => {
            this.stepToMigrateNodes = _.findIndex(data, {
              table_name: "nodes"
            });

            await checkCanvasesMigration({
              group_id: this.contentForm.ac_id
            })
              .then(async data => {
                if (data.data.message) {
                  this.allowDataStoreMigration = true;
                }
              })
              .catch(err => {
                console.log(err);
                success = false;
              });
          })
          .catch(err => {
            console.log(err);
            this.$message({
              type: "error",
              message: __("Error validating account content.")
            });
            success = false;
          });
      } catch (err) {
        this.$message({
          type: "error",
          message: __("Error validating account content.")
        });
        success = false;
      } finally {
        this.btnLoading = false;
        this.isSubmitting = false;
      }
      return success;
    },

    async handleContentMigration() {
      const res = await checkContentMigration({
        group_id: this.contentForm.ac_id,
        migration_job_uuid: this.migrationJobId
      });
      if (res.data.length === 0) {
        this.step++;
        this.$echo
          .channel("nodes_migration_ac_" + this.contentForm.ac_id)
          .listen("NodesMigrationStats", eventData => {
            if (eventData && eventData.node_type) {
              this.componentKey++;
              let index = _.findIndex(this.nodeChunksMigrationResults, {
                item: eventData.node_type
              });

              let status =
                eventData.chunk_count == eventData.chunk_migrated
                  ? "success"
                  : "Migrating";
              let result = {
                item: eventData.node_type,
                result: status,
                s6_num: eventData.chunk_count,
                s7_num: eventData.chunk_migrated
              };

              if (index >= 0) {
                this.$set(this.nodeChunksMigrationResults, index, result);
              } else {
                this.nodeChunksMigrationResults.push(result);
              }
            }
          });
        await this.migrateContent();
        return;
      } else if (res.data[0].allow_skip) {
        this.$confirm(
          // eslint-disable-next-line
            __(":table_name are still in progress in background, :migrated / :total items :completed_percentage % completed", {
              table_name: res.data[0].s6_table_name,
              migrated: res.data[0].total_migrated,
              total: res.data[0].total,
              completed_percentage: res.data[0].completed_percentage
            }
          ),

          // eslint-disable-next-line
            __( "Force proceed to nodes migration"),
          {
            confirmButtonText: __("Move on.."),
            cancelButtonText: __("I will Wait.."),
            type: "warning"
          }
        )
          .then(async () => {
            this.step++;
            await this.migrateContent();
          })
          .catch(() => {});
        return;
      } else {
        this.$message({
          type: "error",

          // eslint-disable-next-line
          message: __(":table_name are still in progress in background, :migrated / :total items :completed_percentage % completed", {
              table_name: res.data[0].s6_table_name,
              migrated: res.data[0].total_migrated,
              total: res.data[0].total,
              completed_percentage: res.data[0].completed_percentage
            }
          )
        });
        return;
      }
    },

    async handleNodeMigration() {
      const res = await checkNodeMigration({
        group_id: this.contentForm.ac_id,
        items_to_include: this.checkedMigrationItems,
        unmapped_instances: _.find(this.accountContentValidation, {
          content_type: "Instances"
        })["unmapped_instances"]
      });
      console.log(res);
      if (res.data.message) {
        this.step++;
        await this.consolidateNodeMigrationProgress({
          s6_group_id: this.contentForm.ac_id,
          migration_job_uuid: this.migrationJobId
        });

        await this.migrateContent();
      } else {
        if (97.5 <= +res.data.completed_percentage) {
          this.$confirm(
            // eslint-disable-next-line
              __("Node migration almost complete :total_migrated / :total_nodes nodes :completed_percentage % completed for migration :migration_job_id", {
                total_migrated: res.data.total_migrated,
                total_nodes: res.data.total_nodes,
                completed_percentage: res.data.completed_percentage,
                migration_job_id: this.migrationJobId
              }
            ),

            // eslint-disable-next-line
              __( "Force proceed to canvas migration (NOT Recommended, please wait)"),
            {
              confirmButtonText: __("Move on.."),
              cancelButtonText: __("I will Wait.."),
              type: "warning",

              beforeClose: (action, instance, done) => {
                if (action) {
                  this.consolidateNodeMigrationProgress({
                    s6_group_id: this.contentForm.ac_id,
                    migration_job_uuid: this.migrationJobId
                  }).then(() => {
                    done();
                  });
                } else {
                  done();
                }
              }
            }
          )
            .then(async () => {
              this.step++;
              await this.migrateContent();
            })
            .catch(() => {});
        } else {
          this.$message({
            type: "error",

            // eslint-disable-next-line
            message: __("nodes are still in progress in background, :total_migrated / :nodes nodes :completed_percentage % completed", {
                total_migrated: res.data.total_migrated,
                nodes: res.data.total_nodes,
                completed_percentage: res.data.completed_percentage
              }
            )
          });
        }
      }
    },

    async handleFixLinkTaskNodes() {
      try {
        const response = await this.fixLinkTaskNodesForAccount({
          s6_group_id: this.contentForm.ac_id,
          s6_task_ids: this.checkedMigratedItems.tasks.map(task => task.value)
        });

        let processed = response.length;
        let updatedCount = 0;
        let failedCount = 0;
        let successMessages = [];
        let errorMessages = [];

        response.forEach(item => {
          if (item.s7_target_task_id !== null) {
            updatedCount++;
            successMessages.push(item.message);
          } else {
            failedCount++;
            errorMessages.push(item.message);
          }
        });

        const summaryMessage = `Processed ${processed} nodes: ${updatedCount} updated, ${failedCount} failed.`;
        const messageType = failedCount > 0 ? "warning" : "success";

        this.$message({
          type: messageType,
          message: summaryMessage,
          duration: 5000
        });

        let detailedMessage = "";
        if (successMessages.length > 0) {
          detailedMessage += `<p><strong>Successes:</strong></p><p>${successMessages.join(
            "<br/>"
          )}</p>`;
        }
        if (errorMessages.length > 0) {
          detailedMessage += `<p><strong>Errors:</strong></p><p>${errorMessages.join(
            "<br/>"
          )}</p>`;
        }

        if (detailedMessage) {
          this.$notify({
            title: "Results",
            message: detailedMessage,
            type: "info",
            dangerouslyUseHTMLString: true,
            duration: 10000
          });
        }

        this.pickMigratedItemsDialogVisible = false;
      } catch (error) {
        console.error("Error processing link task nodes:", error);
        this.$message({
          type: "error",
          message: "An error occurred while processing link task nodes."
        });
      }
    },

    // batch migration
    async nextStepBatchMigrateAccounts() {
      this.contentForm.ac_id = "";
      this.allowDataStoreMigration = false;
      this.isBatchMigration = true;
      await this.nextStep();
    },

    cancelBatchMigration() {
      this.isBatchMigration = false;
      this.contentForm.batch_s6_ac_ids.splice(0);
      this.batchMappedS7AcIds.splice(0);
      this.batchMappedS7AcData.splice(0);
      this.batchGroupsToMigrate = Object.assign({}, {});
      this.batchGroupsActiveTabs.splice(0);
      this.batchActiveTabForGroup = Object.assign({}, {});
      this.batchMigrationProgress = Object.assign({}, {});
      this.batchMigrationCheckingStatus = false;
    },

    async getBatchS6S7AccountData(query = "") {
      if (!this.loadS6GroupData || this.contentForm.s6_sp_id === "") return;
      this.s6GroupDataLoading = true;
      try {
        const response = await this.getBatchS6S7AccountDataForSp({
          service_provider_id: this.contentForm.s6_sp_id,
          page: this.s6GroupPage,
          count: this.s6GroupCount,
          query: query
        });

        const groupData = response.data;
        if (!_.isEmpty(groupData)) {
          this.batchS6S7AcData = [...this.batchS6S7AcData, ...groupData];
          this.s6GroupPage += 1;
          this.s7GroupPage += 1;
        }

        this.loadS6GroupData =
          _.isEmpty(groupData) || groupData.length !== this.s6GroupCount;
      } catch (err) {
        console.error(err);
      } finally {
        this.s6GroupDataLoading = false;
      }
    },

    getBatchS6S7AccountDataWithSearch(query) {
      this.batchS6S7AcData = [];
      this.s6GroupPage = 0;
      this.s7GroupPage = 0;
      this.loadS6GroupData = true;
      this.getBatchS6S7AccountData(query);
    },

    // batch select accounts
    isValidBatchAccountSelection(acIds) {
      return (
        _.isArray(acIds) &&
        acIds.length > 0 &&
        acIds.length <= this.batchMigrationAccountsMaxLimit
      );
    },

    processBatchAccountSelection(acIds) {
      this.batchMappedS7AcData = this.batchS6S7AcData.filter(ac =>
        acIds.includes(ac.s6_group_id)
      );

      if (this.batchMappedS7AcData.length) {
        this.batchMappedS7AcIds = this.batchMappedS7AcData.map(
          ac => ac.s7_ac_id
        );
        this.loadS7AcData = this.batchMappedS7AcData.length !== this.s7AcCount;
      } else {
        this.batchMappedS7AcIds = [];
      }
      this.s7AcDataLoading = false;
      this.s7AcPage += 1;
    },

    resetBatchAccountSelection() {
      this.loadS7AcData = false;
      this.s7AcDataLoading = false;
    },

    async handleBatchAccountSelection(acIds) {
      if (!this.batchS6S7AcData.length) {
        await this.getBatchS6S7AccountData();
      }

      this.s7AcPage = 0;
      this.loadS7AcData = true;
      this.s7AcDataLoading = true;

      if (this.isValidBatchAccountSelection(acIds)) {
        this.processBatchAccountSelection(acIds);
      } else {
        this.resetBatchAccountSelection();
      }
    },

    // batch validate accounts
    async handleBatchValidateAccountContent() {
      if (this.batchMappedS7AcData.length === 0) return;

      this.allowDataStoreMigration = false;
      this.btnLoading = true;
      this.isSubmitting = true;

      const s6GroupsToMigrate = this.batchMappedS7AcData.map(batchMappedAc => ({
        group_id: batchMappedAc.s6_group_id,
        group_name: batchMappedAc.s6_group_name,
        migration_job_uuid: uuidv4()
      }));

      try {
        const data = await this.batchValidateAccountContent({
          s6_groups_to_migrate: s6GroupsToMigrate
        });

        data.forEach(group => {
          this.stepToMigrateNodes = _.indexOf(group.content_types, "nodes");
          this.$set(this.batchGroupsToMigrate, group.group_id, {
            ...group,
            tasks: []
          });
        });

        this.batchGroupsActiveTabs = Object.keys(this.batchGroupsToMigrate).map(
          Number
        );

        this.batchMigrationTasksMaxLimit = await this.fetchBatchMigrationTaskLimit();
      } catch (err) {
        console.log(err);
        this.batchGroupsToMigrate = null;
        this.batchGroupsActiveTabs = [];
        this.$message({
          type: "error",
          message: __("Error batch validating account contents.")
        });
      } finally {
        this.btnLoading = false;
        this.isSubmitting = false;
      }
    },

    // batch select tasks
    async fetchBatchMigrationTaskLimit() {
      const defaultTaskLimit = 50;
      try {
        const response = await getSystemSettings({
          search: "batch_migration_task_limit"
        });

        if (
          response &&
          response.data &&
          response.data.data &&
          Array.isArray(response.data.data)
        ) {
          const setting = response.data.data.find(
            item => item.setting_name === "batch_migration_task_limit"
          );
          if (setting && setting.setting_value !== undefined) {
            const taskLimit = setting.setting_value;
            console.log("Task Limit:", taskLimit);
            return taskLimit;
          } else {
            console.error("Task limit setting not found in response");
            return defaultTaskLimit;
          }
        } else {
          console.error("Invalid response format", response.data.data);
          return defaultTaskLimit;
        }
      } catch (error) {
        console.error("Error fetching system settings:", error);
        return defaultTaskLimit;
      }
    },

    loadTasksForBatchMigration(s6GroupData) {
      return _.get(s6GroupData, "contents.tasks.options.data_to_map", {});
    },

    loadContentsForBatchMigration(s6GroupData) {
      return _.toArray(s6GroupData.contents);
    },

    handleSelectTasksForBatchMigration(tasks, groupId) {
      const tasksArray = tasks.map(taskId => ({
        value: taskId
      }));
      this.$set(this.batchGroupsToMigrate[groupId], "tasks", tasksArray);
    },

    // batch migrate accounts
    initializeBatchMigrationProgress(groupToMigrate) {
      const {
        contents,
        nodes,
        canvases
      } = this.getTotalJobsForBatchMigrationGroup(groupToMigrate);
      this.$set(this.batchMigrationProgress, groupToMigrate.group_id, {
        contents_total: contents,
        nodes_total: nodes,
        canvases_total: canvases,
        datastore_data_total: 0,
        contents_migrated: 0,
        nodes_migrated: 0,
        canvases_migrated: 0,
        datastore_data_migrated: 0,
        percentage: 0,
        datastore_data_percentage: 0,
        details: [],
        nodes_migration_triggered: false,
        canvases_migration_triggered: false,
        nodes_migration_started: false,
        canvases_migration_started: false,
        datastore_data_migration_started: false,
        show_details: false,
        migration_failed: false,
        migration_error: ""
      });
    },

    listenToBatchMigrationUpdates(groupToMigrate, contentToMigrate) {
      this.$echo
        .channel(
          `batch_migration.${groupToMigrate.migration_job_uuid}.${contentToMigrate}`
        )
        .listen("BatchMigrationUpdated", eventData => {
          if (eventData) {
            this.updateBatchMigrationProgress(groupToMigrate, eventData);
          }
        });
    },

    async updateBatchMigrationProgress(groupToMigrate, eventData) {
      const newProgress = {
        ...this.batchMigrationProgress[groupToMigrate.group_id]
      };

      if (eventData.status === "Complete") {
        if (eventData.content_type === "nodes") {
          newProgress.nodes_migrated++;
        } else if (eventData.content_type === "canvases") {
          newProgress.canvases_migrated++;
        } else if (eventData.content_type === "datastore_data") {
          newProgress.datastore_data_migrated++;
        } else {
          newProgress.contents_migrated++;
        }
      } else if (eventData.status === "Failed") {
        newProgress.migration_failed = true;
        newProgress.migration_error = eventData.context;
        this.$alert(
          `${eventData.context || "Error during batch migration."}`,
          `${groupToMigrate.group_name} (ID: ${groupToMigrate.group_id}): Batch Migration Error`,
          {
            type: "error",
            confirmButtonText: "OK",
            callback: () => {
              this.$message({
                type: "error",
                message: `${groupToMigrate.group_name} (ID: ${
                  groupToMigrate.group_id
                }): ${eventData.context || "Error during batch migration."}`
              });
            }
          }
        );
        this.isMigrating = false;
        this.isSubmitting = false;
      }

      newProgress.details.push({
        content_type: eventData.content_type,
        status: eventData.status,
        queue_id: eventData.queue_id,
        context: eventData.context
      });

      if (eventData.content_type === "datastore_data") {
        if (eventData.status === "Dispatching" && eventData.context) {
          console.log("context", eventData.context);
          newProgress.datastore_data_total += Number(eventData.context);
          console.log("total now", newProgress.datastore_data_total);
        } else if (eventData.status === "Complete") {
          newProgress.datastore_data_percentage = Math.round(
            (newProgress.datastore_data_migrated /
              newProgress.datastore_data_total) *
              100
          );
        }
        this.$set(
          this.batchMigrationProgress,
          groupToMigrate.group_id,
          newProgress
        );
      } else {
        const totalMigrated =
          newProgress.contents_migrated +
          newProgress.nodes_migrated +
          newProgress.canvases_migrated;
        const totalJobs =
          newProgress.contents_total +
          newProgress.nodes_total +
          newProgress.canvases_total;
        newProgress.percentage = Math.round((totalMigrated / totalJobs) * 100);

        this.$set(
          this.batchMigrationProgress,
          groupToMigrate.group_id,
          newProgress
        );

        if (
          newProgress.contents_migrated === newProgress.contents_total &&
          newProgress.nodes_migrated === 0 &&
          !newProgress.nodes_migration_triggered
        ) {
          newProgress.nodes_migration_triggered = true;
          this.$set(
            this.batchMigrationProgress,
            groupToMigrate.group_id,
            newProgress
          );
          await this.handleCheckBatchContentMigrationStatus(groupToMigrate);
        }

        if (
          newProgress.nodes_migrated === newProgress.nodes_total &&
          newProgress.canvases_migrated === 0 &&
          !newProgress.canvases_migration_triggered
        ) {
          newProgress.canvases_migration_triggered = true;
          this.$set(
            this.batchMigrationProgress,
            groupToMigrate.group_id,
            newProgress
          );
          await this.handleCheckBatchNodesMigrationStatus(groupToMigrate);
        }

        if (totalMigrated === totalJobs) {
          await this.handleCheckBatchCanvasMigrationStatus(groupToMigrate);
        }
        this.updateBatchMigrationState(totalMigrated === totalJobs);
      }
    },

    updateBatchMigrationState(isComplete) {
      this.isMigrating = !isComplete;
      this.isSubmitting = !isComplete;
      this.componentKey++;
    },

    isBatchMigrationProgressAvailable(groupId) {
      return this.batchMigrationProgress[groupId];
    },

    shouldShowBatchMigrationCheckStatusButton(groupId) {
      const progress = this.batchMigrationProgress[groupId];
      return progress && progress.percentage !== 100;
    },

    getCheckBatchMigrationStatusButtonLabel(groupId) {
      const progress = this.batchMigrationProgress[groupId];
      if (
        !progress.nodes_migration_started &&
        !progress.canvases_migration_triggered
      ) {
        return __("Stuck? Check Content Migration Status");
      } else if (
        progress.nodes_migration_started &&
        !progress.canvases_migration_started
      ) {
        return __("Stuck? Check Nodes Migration Status");
      } else if (
        progress.nodes_migration_started &&
        progress.canvases_migration_triggered &&
        progress.canvases_migration_started
      ) {
        return __("Stuck? Check Canvas Migration Status");
      } else {
        console.log(progress);
        return __("Stuck? Check Migration Status");
      }
    },

    handleCheckBatchMigrationStatusButtonClick(groupToMigrate) {
      const progress = this.batchMigrationProgress[groupToMigrate.group_id];
      if (
        !progress.nodes_migration_started &&
        !progress.canvases_migration_triggered
      ) {
        this.handleCheckBatchContentMigrationStatus(groupToMigrate);
      } else if (
        progress.nodes_migration_started &&
        !progress.canvases_migration_started
      ) {
        this.handleCheckBatchNodesMigrationStatus(groupToMigrate);
      } else if (
        progress.nodes_migration_started &&
        progress.canvases_migration_triggered &&
        progress.canvases_migration_started
      ) {
        this.handleCheckBatchCanvasMigrationStatus(groupToMigrate);
      }
    },

    async handleBatchMigrateAccountContent() {
      this.isMigrating = true;
      this.isSubmitting = true;

      const batchGroupsToMigrateArray = _.toArray(this.batchGroupsToMigrate);
      for (const groupToMigrate of batchGroupsToMigrateArray) {
        this.initializeBatchMigrationProgress(groupToMigrate);

        for (const contentToMigrate of groupToMigrate.content_types) {
          this.listenToBatchMigrationUpdates(groupToMigrate, contentToMigrate);
        }
      }

      try {
        await this.batchMigrateAccountContent({
          s6_groups_to_migrate: batchGroupsToMigrateArray
        });
      } catch (err) {
        console.error(err, "handleBatchMigrateAccountContent");
        await this.$alert(
          err.response.data.message || "Error during batch migration.",
          "Batch Migration Error",
          {
            type: "error",
            confirmButtonText: "OK",
            callback: () => {
              this.step--;
            }
          }
        );
      } finally {
        this.isMigrating = false;
        this.isSubmitting = false;
      }
    },

    async handleCheckBatchContentMigrationStatus(groupToMigrate) {
      this.batchMigrationCheckingStatus = true;
      const res = await checkContentMigration({
        group_id: groupToMigrate.group_id,
        migration_job_uuid: groupToMigrate.migration_job_uuid
      });

      if (res.data.length === 0) {
        this.$set(
          this.batchMigrationProgress[groupToMigrate.group_id],
          "contents_migrated",
          _.get(
            this.batchMigrationProgress[groupToMigrate.group_id],
            "contents_total"
          )
        );
        this.$message({
          type: "info",
          message: `${groupToMigrate.group_name} (ID: ${groupToMigrate.group_id}): Node migration underway!`
        });
        await this.handleBatchMigrateAccountNodesCanvases(
          "nodes",
          groupToMigrate
        );
      } else {
        this.$set(
          this.batchMigrationProgress[groupToMigrate.group_id],
          "nodes_migration_started",
          false
        );
        const remainingContents = res.data.reduce(
          (sum, item) => sum + (item.remaining || 0),
          0
        );
        const remainingInfo =
          remainingContents > 0 ? `(${remainingContents} remaining)` : "";
        this.$message({
          type: "info",
          message: `${groupToMigrate.group_name} (ID: ${groupToMigrate.group_id}): Content migration in progress! Hang tight and check back later. ${remainingInfo}.`
        });
      }
      this.batchMigrationCheckingStatus = false;
    },

    async handleCheckBatchNodesMigrationStatus(groupToMigrate) {
      this.batchMigrationCheckingStatus = true;
      const res = await checkNodeMigration({
        group_id: groupToMigrate.group_id,
        items_to_include: {
          tasks: groupToMigrate.tasks
        }
      });
      if (res.data.remaining === 0) {
        this.$set(
          this.batchMigrationProgress[groupToMigrate.group_id],
          "nodes_migrated",
          _.get(
            this.batchMigrationProgress[groupToMigrate.group_id],
            "nodes_total"
          )
        );

        this.$message({
          type: "info",
          message: `${groupToMigrate.group_name} (ID: ${groupToMigrate.group_id}): Almost done! Canvas migration underway!`
        });
        await this.handleBatchMigrateAccountNodesCanvases(
          "canvases",
          groupToMigrate
        );
      } else {
        this.$set(
          this.batchMigrationProgress[groupToMigrate.group_id],
          "canvases_migration_started",
          false
        );
        this.$message({
          type: "info",
          message: `${groupToMigrate.group_name} (ID: ${groupToMigrate.group_id}): Node migration in progress! Hang in there and check back soon. (${res.data.remaining} remaining).`
        });
      }
      this.batchMigrationCheckingStatus = false;
    },

    async handleCheckBatchCanvasMigrationStatus(groupToMigrate) {
      this.batchMigrationCheckingStatus = true;
      const res = await checkCanvasesMigration({
        group_id: groupToMigrate.group_id,
        migration_job_uuid: groupToMigrate.migration_job_uuid
      });
      if (res.data.remaining === 0) {
        this.$set(
          this.batchMigrationProgress[groupToMigrate.group_id],
          "canvases_migrated",
          _.get(
            this.batchMigrationProgress[groupToMigrate.group_id],
            "canvases_total"
          )
        );
        this.$set(
          this.batchMigrationProgress[groupToMigrate.group_id],
          "percentage",
          100
        );
        this.$message({
          type: "success",
          message: `${groupToMigrate.group_name} (ID: ${groupToMigrate.group_id}): Migration complete!! Hooray!`
        });

        await this.handleBatchMigrateDatastoreData(groupToMigrate);
      } else {
        this.$message({
          type: "info",
          message: `${groupToMigrate.group_name} (ID: ${groupToMigrate.group_id}): Canvas migration in progress! Hang in there and check back soon. (${res.data.remaining} remaining).`
        });
      }
      this.batchMigrationCheckingStatus = false;
    },

    async handleBatchMigrateAccountNodesCanvases(
      tableToMigrate,
      groupToMigrate
    ) {
      try {
        let migrationStarted =
          tableToMigrate === "nodes"
            ? _.get(
                this.batchMigrationProgress[groupToMigrate.group_id],
                "nodes_migration_started"
              )
            : _.get(
                this.batchMigrationProgress[groupToMigrate.group_id],
                "canvases_migration_started"
              );

        if (migrationStarted) {
          MessageBox.confirm(
            `The ${tableToMigrate} migration has already begun. Would you like to force a retry? Please note that this could result in duplicate records or errors.`,
            `${groupToMigrate.group_name} (ID: ${groupToMigrate.group_id})`,
            {
              confirmButtonText: "Yes",
              cancelButtonText: "No",
              type: "warning"
            }
          ).then(async () => {
            migrationStarted = false;
          });
        }
        if (!migrationStarted) {
          if (tableToMigrate === "nodes") {
            this.$set(
              this.batchMigrationProgress[groupToMigrate.group_id],
              "nodes_migration_started",
              true
            );
          } else {
            this.$set(
              this.batchMigrationProgress[groupToMigrate.group_id],
              "canvases_migration_started",
              true
            );
          }

          await this.migrateAccountContent({
            options: groupToMigrate.contents,
            unmapped_instances: [],
            group_id: groupToMigrate.group_id,
            migration_step:
              tableToMigrate == "nodes"
                ? this.stepToMigrateNodes
                : this.stepToMigrateNodes + 1,
            migration_job_uuid: groupToMigrate.migration_job_uuid,
            items_to_include: {
              tasks: groupToMigrate.tasks
            },
            tables_to_include: [tableToMigrate]
          });
        }
      } catch (err) {
        console.error(err);
        tableToMigrate === "nodes"
          ? this.$set(
              this.batchMigrationProgress[groupToMigrate.group_id],
              "nodes_migration_started",
              false
            )
          : this.$set(
              this.batchMigrationProgress[groupToMigrate.group_id],
              "canvases_migration_started",
              false
            );

        this.$set(
          this.batchMigrationProgress[groupToMigrate.group_id],
          "migration_failed",
          true
        );

        this.$set(
          this.batchMigrationProgress[groupToMigrate.group_id],
          "migration_error",
          err.message || "Error during batch migration."
        );

        this.$alert(
          err.message || "Error during batch migration.",
          `${groupToMigrate.group_name} (ID: ${groupToMigrate.group_id}): Batch Migration Error`,
          {
            type: "error",
            confirmButtonText: "OK",
            callback: () => {
              this.$message({
                type: "error",
                message: `${groupToMigrate.group_name} (ID: ${
                  groupToMigrate.group_id
                }): ${err.message || "Error during batch migration."}`
              });
            }
          }
        );
      } finally {
        this.isMigrating = false;
        this.isSubmitting = false;
      }
    },

    listenToBatchDatastoreDataMigrationUpdates(groupToMigrate) {
      this.$echo
        .channel(
          `batch_migration.${groupToMigrate.migration_job_uuid}.datastore_data`
        )
        .listen("BatchMigrationUpdated", eventData => {
          if (eventData) {
            this.updateBatchMigrationProgress(groupToMigrate, eventData);
          }
        });
    },

    async handleBatchMigrateDatastoreData(groupToMigrate) {
      MessageBox.confirm(
        "Migration complete!! Hooray! Do you want to start datastore migration? If you select 'No', you won't be able to continue with the datastore migration from this page later.",
        `${groupToMigrate.group_name} (ID: ${groupToMigrate.group_id})`,
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning"
        }
      ).then(async () => {
        this.listenToBatchDatastoreDataMigrationUpdates(groupToMigrate);
        this.$set(
          this.batchMigrationProgress[groupToMigrate.group_id],
          "datastore_data_migration_started",
          true
        );
        await migrateDataStoreDataForAccount({
          group_id: groupToMigrate.group_id,
          migration_job_uuid: groupToMigrate.migration_job_uuid,
          dataStoreIds: {},
          rowCount: 0
        })
          .then(data => {
            if (data.data.count) {
              const datastore_data_total =
                _.get(
                  this.batchMigrationProgress[groupToMigrate.group_id],
                  "datastore_data_total",
                  0
                ) + Number(data.data.count);

              this.$set(
                this.batchMigrationProgress[groupToMigrate.group_id],
                "datastore_data_total",
                datastore_data_total
              );
              this.$message({
                type: "success",
                message:
                  `${groupToMigrate.group_name} (ID: ${groupToMigrate.group_id}): ` +
                  __("Data store data migration triggered successfully.")
              });
            } else {
              this.$set(
                this.batchMigrationProgress[groupToMigrate.group_id],
                "datastore_data_percentage",
                100
              );
              this.$message({
                type: "success",
                message:
                  `${groupToMigrate.group_name} (ID: ${groupToMigrate.group_id}): ` +
                  __("No Datastore to migration")
              });
            }
          })
          .catch(err => {
            console.log(err);
            this.$message({
              type: "error",
              message:
                `${groupToMigrate.group_name} (ID: ${groupToMigrate.group_id}): ` +
                __("Data store data migration failed.")
            });
          });
      });
    },

    // show batch migration progress
    getBatchMigrationProgressPercentage(groupId) {
      const percentage = _.get(
        this.batchMigrationProgress[groupId],
        "percentage",
        0
      );
      return percentage > 100 ? 100 : percentage;
    },

    getBatchMigrationProgressDetails(groupId) {
      return _.get(this.batchMigrationProgress[groupId], "details", []);
    },

    getTotalJobsForBatchMigrationGroup(groupToMigrate) {
      if (!groupToMigrate || typeof groupToMigrate !== "object") {
        throw new Error("Invalid groupToMigrate object");
      }

      const { content_types, node_types, tasks } = groupToMigrate;
      if (
        !Array.isArray(content_types) ||
        !Array.isArray(tasks) ||
        !Array.isArray(node_types)
      ) {
        throw new Error(
          "Invalid content_types or tasks or node_types properties"
        );
      }

      let contentsTotal = 0;
      let nodesTotal = 0;
      let canvasesTotal = 0;
      const noOfTasks = tasks.length;
      try {
        contentsTotal = content_types.length - 2; // exclude nodes and canvases
        contentsTotal += noOfTasks > 1 ? (noOfTasks - 1) * 4 : 0; // Calculate process per task jobs: local variables, general prompts, audio variables, local array variables
        nodesTotal = node_types.length;
        canvasesTotal = noOfTasks; // 1 per task
      } catch (error) {
        console.error("Error calculating total jobs:", error);
        throw new Error("Failed to calculate total jobs");
      }
      return {
        contents: contentsTotal,
        nodes: nodesTotal,
        canvases: canvasesTotal
      };
    },

    batchMigrationStatusClass(status) {
      if (status === "Complete") return "text-success";
      if (status === "Failed") return "text-danger";
      return "";
    },

    getBatchDatastoredataMigrationProgressPercentage(groupId) {
      const percentage = _.get(
        this.batchMigrationProgress[groupId],
        "datastore_data_percentage",
        0
      );
      return percentage > 100 ? 100 : percentage;
    },

    handleAddToBatch() {
      if (_.isEmpty(this.checkedTables)) {
        this.$message({
          type: "warning",
          message: __("Please select tables to add to batch migration.")
        });
        return;
      }

      if (
        _.isEmpty(this.checkedMigrationItems) ||
        !this.checkedMigrationItems.tasks
      ) {
        this.$message({
          type: "warning",
          message: __(
            "Please select specific tasks to migrate by clicking the 'Pick Items to Migrate' button."
          )
        });
        return;
      }

      this.batchLoading = true;

      // Extract the selected tables to migrate from checkedTables
      const tablesToMigrate = this.checkedTables
        .filter(item => item.table_name)
        .map(item => item.table_name);

      // Extract task IDs directly from checkedMigrationItems
      const taskIds = this.checkedMigrationItems.tasks
        ? this.checkedMigrationItems.tasks.map(task => task.value)
        : [];

      const payload = {
        s6_group_id: this.contentForm.ac_id,
        s7_account_id: this.mapped_s7_ac_id,
        tables_to_migrate: tablesToMigrate,
        s6_task_ids: taskIds,
        tasks_per_batch: this.tasksPerBatch
      };

      console.log("Batch migration payload:", payload);

      this.addBatchMigration(payload)
        .then(() => {
          this.$message({
            type: "success",
            message: __("Tasks successfully added to batch migration.")
          });
          // Clear selections after successful addition
          if (this.$refs.userActionsTable) {
            this.$refs.userActionsTable.clearSelection();
          }
          this.checkedTables = [];
        })
        .catch(err => {
          console.error(err);
          this.$message({
            type: "error",
            message: __("Failed to add tasks to batch migration.")
          });
        })
        .finally(() => {
          this.batchLoading = false;
        });
    }
  },

  beforeDestroy: function() {
    this.$echo.leave("nodes_migration_ac_" + this.contentForm.ac_id);
    this.$echo.leave("canvas_migration_failed." + this.migrationJobId);
  },

  watch: {
    pickMigrationItemsDialogVisible: {
      immediate: true,
      handler(val) {
        if (val === true) {
          let checkedMigrationItems = _.get(
            this.checkedMigrationItems,
            this.pickMigrationItemTableName
          );
          if (!_.isEmpty(checkedMigrationItems)) {
            this.$nextTick(() => {
              checkedMigrationItems.forEach(item => {
                let itemIndex = _.findIndex(
                  this.selectiveMigrationDataToMap,
                  item
                );
                this.$refs.migrationItemsTablesRef.toggleRowSelection(
                  this.selectiveMigrationDataToMap[itemIndex],
                  true
                );
              });
            });
          }
        }
      }
    },

    "contentForm.batch_s6_ac_ids"(newVal) {
      if (newVal.length > this.batchMigrationAccountsMaxLimit) {
        this.$message({
          message: `You cannot select more than ${this.batchMigrationAccountsMaxLimit} accounts.`,
          type: "error"
        });
        this.contentForm.batch_s6_ac_ids.pop();
      }
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/content-list.scss";
@import "~@/styles/tags.scss";
.item {
  margin: 4px;
}
::v-deep .el-radio {
  :hover {
    border-color: $content-theme-color;
  }
  .el-radio__input.is-checked .el-radio__inner {
    border-color: $content-theme-color;
    background: $content-theme-color;
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: $content-theme-color;
  }
}
::v-deep .el-checkbox {
  :hover {
    border-color: $content-theme-color;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    border-color: $content-theme-color;
    background: $content-theme-color;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: $content-theme-color;
  }
}
::v-deep .advancedBtn {
  margin-left: 10px;
  height: 44px !important;
  width: 25px !important;
  border-color: #f5f5f8;
  color: #a0a8b5;
  background-color: transparent;
  &:hover {
    background-color: transparent;
    border-color: var(--theme-color);
    ::v-deep svg {
      fill: var(--theme-hover-color);
      path {
        fill: var(--theme-hover-color);
      }
    }
    .bulkImg {
      width: 24px;
      margin-left: -10px;
    }
  }
  &.is-disabled:hover,
  &.is-disabled {
    color: #a0a8b5;
    border-color: #f5f5f8;
    ::v-deep svg {
      fill: #a0a8b5;
      path {
        fill: #a0a8b5;
      }
    }
  }
}
</style>

<style lang="scss">
.batch-migrate-group {
  > div > .el-collapse-item__header {
    color: #409eff;
    background: #ecf5ff;
    border-color: #b3d8ff;
    padding-left: 15px;
    padding-right: 15px;
  }

  .batch-migrate-group-contents {
    padding-left: 25px;
    padding-right: 25px;

    .el-collapse-item__header {
      font-weight: bold;
    }
  }
}

.batch-migrate-progress {
  padding-bottom: 30px;

  .title {
    font-size: 20px;
  }

  .subtitle {
    color: #606266;
    font-size: 13px;
  }

  .details {
    padding: 15px;
    color: #606266;
    font-size: 13px;

    .text-success {
      color: #67c23a;
    }

    .text-danger {
      color: #f56c6c;
    }
  }
}

.mb-15 {
  margin-bottom: 15px;
}

.pt-15 {
  padding-top: 15px;
}
</style>
